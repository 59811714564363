.footerBg {
  background: #0d0d0d;
  padding-top: 6.5rem;
  padding-bottom: 5rem;
}

.footer {
  color: #edffed60;

  ul {
    padding-left: 0;
    list-style: none;
  }
}
.sec1 {
  display: grid;
  grid-template-columns: 0.7fr 1fr 1.2fr 1.3fr;
  gap: 2rem;
}

.ttl {
  color: #edffed;
  font-size: 15px;
  margin-bottom: 1rem;
}
.item {
  margin-bottom: 12px;
  font-size: 15px;
  display: block;
  color: inherit;
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
    color: var(--primary-green);
  }
}

a.item:hover {
  color: var(--primary-green);
}

.subscribe {
  background: rgba(127, 194, 65, 0.05);
  padding: 2.2rem 2.5rem;
  margin-top: -1rem;
  height: fit-content;

  .inputWrap {
    display: flex;
    margin-bottom: 2rem;

    input {
      border-radius: 6px;
      border: 1.5px solid #16291b;
      background: #0d0d0d;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 14px;
      color: #fff;
    }

    svg {
      cursor: pointer;
    }
  }
  &__txt {
    font-size: 13px;
    line-height: 1.4;
  }
}
.address {
  max-width: 200px;
}

.sec2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid #edffed50;
  width: 90%;
  margin-left: auto;
  margin-right: auto;

  img {
    width: 72px;
  }

  .otherLinks {
    display: flex;

    a {
      margin: 0 1rem;
      font-size: 13px;
      color: #edffed;
    }
  }

  .socials {
    display: flex;
    a {
      margin-left: 10px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .sec1 {
    grid-template-columns: 1fr 1fr;
  }
  .subscribe {
    margin-top: 0;
    // max-width: 360px;
  }
}

@media screen and (max-width: 800px) {
  .footerBg {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .sec1 {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .ttl {
    font-size: 14px;
  }

  .item {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .subscribe {
    padding: 1.5rem 1.5rem 3rem;
  }

  .sec2 {
    display: grid;
    gap: 1.5rem;
    margin-top: 1.5rem;
    width: 100%;
    padding-top: 1.5rem;

    .otherLinks a {
      margin-left: 0;
      margin-right: 2rem;
    }

    .socials a {
      margin-right: 10px;
      margin-left: 0;
    }
  }
}
