.container {
  background: #1b1f2775;
  position: fixed;
  z-index: 10;
  height: 100vh;
  // max-height: 100vh;
  width: 100vw;
  -webkit-overflow-scrolling: touch;
}

.content {
  width: 50%;
  margin-left: auto;
  background: #fff;
  min-width: 600px;
  overflow: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  right: -50%;
  position: relative;
  animation: slideIn 1s ease-in-out 1 forwards;
  position: relative;
  -webkit-transform: translateZ(0);

  &__close {
    animation: slideOut 1s ease-in-out 1 forwards;
  }
}

@keyframes slideIn {
  0% {
    right: -700px;
  }
  100% {
    right: 0;
  }
}

@keyframes slideOut {
  0% {
    right: 0;
  }
  100% {
    right: -700px;
  }
}
.body {
  padding: 1.5rem;
  padding-bottom: 0;
}
.close {
  position: absolute;
  right: 1.5rem;
  background: #fff;
  border-radius: 50%;
  padding: 2px;
}
.ttl {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 1rem;
}
.txt {
  color: rgba(27, 31, 39, 0.5);
  margin-bottom: 1.5rem;
}
.form {
  width: 60%;
  min-width: 350px;
}
.inputWrap {
  margin-bottom: 1.2rem;

  label {
    display: block;
    color: #374151;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
  }

  input,
  textarea {
    display: block;
    border-radius: 6px;
    border: 1px solid var(--gray-300, #d1d5db);
    background: var(--white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 7px 12px;
  }

  textarea {
    resize: none;
    height: 120px;
    font-size: 14px;
  }

  p {
  }
}
label.upload {
  display: flex;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 7px 12px;
  justify-content: space-between;
  align-items: center;
  height: 33px;

  svg {
    width: 16px;
  }
}
.selectWrap {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 1.25rem;
}

/* select box */
.select {
  [class*="formSelect__indicator-separator"] {
    display: none;
  }

  [class*="formSelect__control"] {
    min-height: unset;
    width: 100%;
    border: 1px solid var(--gray-300, #d1d5db);
    background: transparent;
    height: 100%;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    font-weight: 500;
  }

  [class*="formSelect__control--is-focused"] {
    /* background: transparent; */
    border-color: #e5e5e5 !important;
    box-shadow: none;
  }

  [class*="formSelect__input-container"] {
    height: 100%;
    margin: 0;
  }

  [class*="formSelect__value-container"] {
    padding: 0 0 0 1rem;
  }

  [class*="formSelect__placeholder"] {
    color: #4e5d78;
    opacity: 0.8;
    font-size: 14px;
  }

  [class*="formSelect__dropdown-indicator"] {
    height: 33px;
  }
}
.btn {
  border-radius: 8px;
  border: 1px solid #03a300;
  background: #03a300;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  width: 100%;
  padding: 12px;
  font-size: 15px;
  margin-top: 10px;
}

.btnSec {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e5e7eb;
  padding: 1rem 1.5rem;

  button {
    font-weight: 600;
    padding: 9px 18px;
    margin-left: 1rem;
    border-radius: 6px;
  }

  .cancel {
    border: 1px solid var(--gray-300, #d1d5db);
    background: var(--bg-color-100, #fff);
    color: #1b1f27;
    /* shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }

  .submit {
    background: var(
      --gradient,
      linear-gradient(129deg, #013f00 0%, #48d911 50.95%, #5aff15 100%)
    );

    /* shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: #fff;
    border: none;
  }
}
.errorMessage {
  color: red;
  font-size: 11px;
  margin-top: 8px;
}
.msg {
  font-size: 12px;
  color: #6B7280;
}
@media screen and (max-width: 800px) {
  .content {
    width: 90%;
    min-width: unset;
    right: -100%;
  }
  .body {
    padding: 1rem 1rem 0;
  }
  .form {
    width: 100%;
    max-width: 370px;
    min-width: unset;
  }
  .ttl {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  .close {
    right: 12px;
  }
  @keyframes slideIn {
    0% {
      right: -100%;
    }
    100% {
      right: 0;
    }
  }

  @keyframes slideOut {
    0% {
      right: 0;
    }
    100% {
      right: -100%;
    }
  }
}
