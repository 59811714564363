.main {
  > section:first-of-type {
    opacity: 0.2;
    animation: fadeIn 1s ease-in 1 forwards;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
