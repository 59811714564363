.newsfeed {
  padding-top: 1rem;
  padding-bottom: 4rem;

  &__ttl {
    font-weight: 600;
    color: #1b1f27;
    font-size: 32px;
    margin: 0;
    margin-bottom: 2rem;
  }
}
.posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}
.post {
  border-radius: 12px;
  border: 1px solid rgba(27, 31, 39, 0.05);
  background: #fff;
  padding: 1.4rem;

  img {
    width: 100%;
    margin-bottom: 1rem;
    aspect-ratio: 1/1;
    border-radius: 8px;
    object-fit: cover;

  }

  &__ttl {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 1rem;
  }

  &__txt {
    font-size: 15px;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: rgba(27, 31, 39, 0.5);
  }

  &__tag {
    border-radius: 100px;
    background: rgba(27, 31, 39, 0.05);
    padding: 6px 10px;
    margin-bottom: 14px;
    color: rgba(27, 31, 39, 0.5);
    margin-right: 1rem;
    font-size: 15px;
    margin-bottom: 1.5rem;
    display: inline-block;
  }

  &__read {
    display: block;
    border-radius: 6px;
    border: 1px solid #013f00;
    background: #fff;
    color: #1b1f27;
    font-size: 15px;
    width: fit-content;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 17px;
    text-decoration: none;
  }
}

@media screen and (max-width: 800px) {
  .newsfeed {
    &__ttl {
      text-align: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .newsfeed {
    padding-bottom: 8rem;

    &__ttl {
      font-size: 26px;
      margin-bottom: 1.5rem;
    }
  }

  .post {
    padding: 1rem;

    &__ttl {
      font-size: 17px;
      margin-bottom: 8px;
    }

    &__txt {
      font-size: 14px;
      margin-bottom: 1rem;
    }

    &__tag {
      font-size: 14px;
      margin-bottom: 1.2rem;
    }

    &__read {
      font-size: 14px;
      padding: 10px 16px;
    }
  }
}
