.teamBg {
  background: #f4f4f4;
}
.team {
  padding-top: 4rem;
  padding-bottom: 5rem;

  &__ttl {
    font-weight: 600;
    color: #1b1f27;
    font-size: 32px;
    margin: 0;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__txt {
    font-size: 15px;
    line-height: 1.8;
    margin-bottom: 3rem;
    text-align: center;
    color: rgba(27, 31, 39, 0.5);
  }
}
.boardTtl {
  margin-top: 5rem;
  margin-bottom: 3rem;
}
.members {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
.member {
  border-radius: 12px;
  background: #fff;
  padding: 14px;
  min-height: 270px;
}
.photo {
  width: 100%;
  margin-bottom: 10px;
}
.name {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-bottom: 6px;
}
.role {
  color: rgba(3, 163, 0, 0.9);
  font-size: 13px;
  font-weight: 400;
}
.bio {
  color: #1b1f2780;
  font-size: 15px;
  line-height: 1.8;
}

@media screen and (max-width: 1200px) {
  .members {
    // grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@media screen and (max-width: 800px) {
  .team {
    padding-top: 3rem;

    &__ttl {
      font-size: 26px;
      margin-bottom: 10px;
    }

    &__txt {
      margin-bottom: 1.5rem;
    }
  }
  .boardTtl {
    margin-bottom: 1.5rem;
    margin-top: 4rem;
  }
  .members {
    // gap: 1rem;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .member {
    // padding: 1rem;
  }
  .name {
    // font-size: 18px;
  }
  .role {
    // font-size: 15px;
    // margin-bottom: 6px;
  }
  .bio {
    font-size: 13px;
  }
  .photo {
    // margin-bottom: 10px;
  }
}
