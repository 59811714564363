.containerBg {
  background: #fff;
  padding-bottom: 4.5rem;
}
.container {
  padding-top: 12rem;
  padding-bottom: 2rem;

  &__ttl {
    text-align: center;
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }

  &__txt {
    text-align: center;
    color: rgba(27, 31, 39, 0.5);
    font-size: 19px;
    margin-bottom: 4rem;
  }
}

.faqList {
  max-width: 720px;
  margin: auto;
}

/* FAQ Item */
.faqItem {
  border-top: 1px solid #eaecf0;

  &:first-of-type {
    border: none;
  }

  * {
    transition: 0.3s;
  }
}
.faqBtn {
  width: 100%;
  text-align: left;
  padding: 1.5rem 0;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}
.open {
  padding: 1rem 0 12px;
}
.faqBtn span {
  width: 80%;
  line-height: 1.7;
  color: #1b1f27;
}
.faqBtn svg {
  //   width: 36px;
}
.faqBody {
  padding: 0;
  line-height: 1.6;
  font-size: 15px;
  width: 93%;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
  color: rgba(27, 31, 39, 0.6);
}
.activeItem .faqBody {
  height: auto;
}
.inactiveItem .faqBody {
  animation-name: hideFaq;
}
.faqBody div {
  margin-bottom: 1.7rem;
}

@media screen and (max-width: 800px) {
  .container {
    padding-top: 9rem;

    &__ttl {
      font-size: 26px;
      text-align: left;
      margin-bottom: 1rem;
    }

    &__txt {
      font-size: 15px;
      text-align: left;
      margin-bottom: 2rem;
      line-height: 1.8;
    }
  }

  .faqList {
    max-width: unset;
  }

  .faqBtn {
    font-size: 14px;
    padding: 1.2rem 0;
  }
  .faqBody {
    font-size: 14px;
  }
  .containerBg {
    padding-bottom: 4rem;
  }
}
