.heroBg {
  background: #fff;
}
.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 10rem;
  padding-bottom: 2rem;
}
.content {
  margin-top: 4rem;
}
.ttl {
  font-size: 68px;
  max-width: 600px;
  margin-bottom: 1.2rem;
  position: relative;

  svg {
    margin-bottom: 30px;
    margin-left: -8px;
  }
}
.txt {
  font-size: 15px;
  color: rgba(27, 31, 39, 0.5);
  line-height: 1.8;
  margin-bottom: 10px;
  max-width: 550px;

  a {
    color: var(--primary-green);
    text-decoration: underline;
  }

  br {
    margin-bottom: 1rem;
  }
}
.img {
  width: 100%;
  max-width: 520px;
  margin-left: auto;
  margin-right: -10%;
  background: url(../../../assets/vectors/aboutEllipse.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 1200px) {
  .hero {
    grid-template-columns: 1.5fr 1fr;
    align-items: flex-end;
  }
}

@media screen and (max-width: 1000px) {
  .hero {
    grid-template-columns: 1fr;
    background-image: url(../../../assets/vectors/aboutEllipse.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    padding-bottom: 4rem;
  }
  .content {
    margin: 0;
  }
  .img {
    display: none;
  }
  .ttl {
    font-size: 52px;

    svg {
      width: 80px;
    }
  }
  .txt {
    max-width: unset;
  }
}

@media screen and (max-width: 800px) {
  .hero {
    padding-top: 6.5rem;
  }
  .ttl {
    text-align: center;
    max-width: unset;

    font-size: 36px;

    svg {
      width: 60px;
    }
  }
  .txt {
    text-align: center;
    font-size: 14px;
  }
}
