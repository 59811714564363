.contact {
  border-radius: 16px;
  background: #fafafa;
  padding: 2rem;
  text-align: center;

  img {
    margin-bottom: 1.5rem;
  }

  &__ttl {
    font-weight: 500;
    font-size: 19px;
    margin-bottom: 14px;
  }

  &__txt {
    color: rgba(27, 31, 39, 0.6);
    font-size: 15px;
    margin-bottom: 2rem;
  }

  a {
    border-radius: 8px;
    border: 1px solid #03a300;
    background: #03a300;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: inline-block;
    width: fit-content;
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    padding: 10px 16px;
  }
}

@media screen and (max-width: 1200px) {
  .contact {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 800px) {
  .contact {
    img {
      margin-bottom: 1rem;
    }

    &__ttl {
      font-size: 17px;
      margin-bottom: 12px;
    }

    &__txt {
      line-height: 1.6;
      font-size: 14px;
    }

    a {
      font-size: 14px;
      border-radius: 6px;
    }
  }
}
