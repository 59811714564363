.container {
  padding-top: 6rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  background: #fff;
  align-items: flex-start;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 750px;
}
.contact {
  padding: 1rem 12%;
  max-width: 700px;
}
.ttl {
  font-size: 30px;
  max-width: 450px;
  margin-bottom: 10px;
  font-weight: 600;
}
.txt {
  font-size: 15px;
  margin-bottom: 10px;
  color: #667085;
  max-width: 500px;
  line-height: 1.5;

  a {
    color: inherit;
    margin-right: 4px;
  }
}
.socials {
  margin-bottom: 1rem;

  a {
    margin-right: 10px;
    margin-left: 0;

    svg {
      path {
        opacity: 1;
        fill: #3cba67;

        &:first-of-type {
          stroke: #3cba6710;
          fill: transparent;
        }
      }
    }
  }
}
.form {
}

.inputWrap {
  margin-bottom: 1rem;

  label {
    display: block;
    color: #374151;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
  }

  input,
  textarea {
    display: block;
    border-radius: 6px;
    border: 1px solid var(--gray-300, #d1d5db);
    background: var(--white, #fff);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    padding: 7px 12px;
  }

  textarea {
    resize: none;
    height: 120px;
    font-size: 14px;
  }

  p {
  }
}

.selectWrap {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 1.25rem;
}

/* select box */
.select [class*="formSelect__indicator-separator"] {
  display: none;
}
.select [class*="formSelect__control"] {
  min-height: unset;
  width: 100%;
  border: 1px solid var(--gray-300, #d1d5db);
  background: transparent;
  height: 100%;
  border-radius: 6px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  font-weight: 500;
}
.select [class*="formSelect__control--is-focused"] {
  /* background: transparent; */
  border-color: #e5e5e5 !important;
  box-shadow: none;
}
.select [class*="formSelect__input-container"] {
  height: 100%;
  margin: 0;
}
.select [class*="formSelect__value-container"] {
  padding: 0 0 0 1rem;
}
.select [class*="formSelect__placeholder"] {
  color: #4e5d78;
  opacity: 0.8;
  font-size: 14px;
}
.btn {
  border-radius: 8px;
  border: 1px solid #03a300;
  background: #03a300;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  width: 100%;
  padding: 12px;
  font-size: 15px;
  margin-top: 10px;
}
.errorMessage {
  color: red;
  font-size: 11px;
  margin-top: 8px;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-template-columns: 0.8fr 1fr;
  }
  .contact {
    padding: 4rem 2rem;
  }
}
@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: 1fr;
    padding-top: 8rem;
  }
  .img {
    display: none;
  }
  .contact {
    padding: 1rem;
  }
  .ttl {
    font-size: 32px;
    max-width: 350px;
  }
  .txt {
    font-size: 15px;
    max-width: 350px;
    line-height: 1.8;
  }
  .btn {
    font-size: 14px;
  }
}
