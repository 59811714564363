.containerBg {
  background: #fff;
}
.container {
  padding-bottom: 6rem;
  padding-top: 11rem;
}
.ttl {
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 3.5rem;
  color: #1b1f27;
}
.body {
  padding: 1.8rem;
  padding-top: 2rem;
  border: 1px solid #eaecf0;
  border-radius: 16px;
  margin-bottom: 4rem;
}
.body > div {
  margin-bottom: 1.5rem;
}
.section__ttl {
  color: #1b1f27;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
}
.section__txt1 {
  color: #4e5d78;
  line-height: 1.5;
  margin-bottom: 12px;
  border-bottom: 1px solid #eaecf0;
  padding-bottom: 12px;
}
.section__txt2 {
  color: #4e5d78;
  line-height: 1.5;
}
.section__list {
  color: #4e5d78;
  line-height: 1.5;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 1.2rem;
}

@media screen and (max-width: 1200px) {
  .getInTouch {
    margin: 0;
  }
}
@media screen and (max-width: 900px) {
  .container {
    padding-top: 6.5rem;
  }
  .ttl {
    font-size: 26px;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  .body {
    padding: 1rem;
  }
  .section__ttl {
    font-size: 16px;
  }
}
