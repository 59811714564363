.heroBg {
  background-image: linear-gradient(
      103deg,
      #121212 40%,
      rgba(18, 18, 18, 0.2) 80%
    ),
    url(../../../assets/images/coinPot.png);
  background-size: cover;
  position: relative;
}
.heroBg::after {
  content: "";
  width: 70%;
  height: 100%;
  background: url(../../../assets/vectors/ellipse.svg);
  position: absolute;
  right: 0;
  top: 0;
  background-size: cover;
}
.hero {
  color: #fff;
  padding-bottom: 12rem;
  padding-top: 14rem;
}
.ttl {
  font-size: 68px;
  max-width: 600px;
  margin-bottom: 1.2rem;
  position: relative;

  span {
    position: relative;
  }

  span::after {
    content: "";
    display: block;
    position: absolute;
    background: url(../../../assets/vectors/underline.svg);
    width: 140px;
    height: 55px;
    background-size: cover;
    right: -35px;
    bottom: -28px;
  }
}
.txt {
  font-size: 19px;
  max-width: 550px;
  font-weight: 300;
  margin-bottom: 3rem;
  line-height: 1.5;
}
.btn {
  background: var(--gradient-green);
  color: #fff;
  border: none;
  padding: 1.2rem 3rem;
  border-radius: 6px;
  font-size: 16px;
  letter-spacing: 0.3px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 800px) {
  .heroBg {
    background-position: center;

    .ttl {
      font-size: 50px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 2rem;

      span::after {
        width: 100px;
        height: 35px;
        right: 0;
        left: -4px;
        bottom: -17px;
      }
    }
  }
  .hero {
    padding-top: 10rem;
  }

  .txt {
    text-align: center;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    margin-bottom: 2rem;
  }
  .btn {
    margin: auto;
    display: block;
    font-size: 14px;
    padding: 1rem 2.5rem;
  }
}
